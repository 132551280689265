import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthors } from "../../redux/authors";
import { addAuthor } from "../../api";

import Author from "./author";

export default function NewAuthor() {
  const defaultAuthor = {
    bio: "",
    external_profile_url: "",
    image: "",
    imageHd: "",
    name: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authorSlice = useSelector((state) => state.authors);
  const authors = authorSlice.authors;

  const [saving, setSaving] = useState(false);
  const [author, setAuthor] = useState(defaultAuthor);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    addAuthor(author)
      .then((id) => {
        dispatch(
          setAuthors([
            ...authors,
            {
              ...author,
              id,
            },
          ])
        );
        navigate(`/authors/${id}`);
      })
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  return (
    <Author
      author={author}
      setAuthor={setAuthor}
      saving={saving}
      handleSubmit={handleSubmit}
    />
  );
}
