import { Divider, Stack } from "@mui/joy";

import ArticleStatusSwitch from "./statusSwitch";
import ArticleComponents from "./components";

export default function Article({ article, setArticle }) {
  return (
    <Stack spacing={3}>
      <ArticleStatusSwitch article={article} setArticle={setArticle} />
      <Divider />
      <ArticleComponents article={article} setArticle={setArticle} />
    </Stack>
  );
}
