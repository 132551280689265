import { useState } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateTag } from "../../api";
import { setTags } from "../../redux/tags";

import Tag from "./tag";

export default function UpdateTag() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const tagSlice = useSelector((state) => state.tags);
  const tags = tagSlice.tags;

  const [tag, setTag] = useState(tags.filter((i) => i.id === id)[0]);
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    updateTag(tag.id, tag)
      .then(() =>
        dispatch(
          setTags(
            tags.map((i) => {
              if (i.id === tag.id) {
                return tag;
              }
              return i;
            })
          )
        )
      )
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  return (
    <Tag
      tag={tag}
      setTag={setTag}
      saving={saving}
      handleSubmit={handleSubmit}
    />
  );
}
