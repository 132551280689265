import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { setAuthors } from "../redux/authors";

import { useDispatch, useSelector } from "react-redux";

import { Button, Container, Snackbar, Stack, Typography } from "@mui/joy";

import { getAllAuthors, getAllTags } from "../api";
import { setTags } from "../redux/tags";
import { closeNotify } from "../redux/utils";
import { auth } from "../firebaseConfig";

export default function AppWrapper({ children }) {
  const utils = useSelector((state) => state.utils);
  const notify = utils.notify;

  const [loadingAuthors, setLoadingAuthors] = useState(true);
  const [loadingTags, setLoadingtags] = useState(true);

  const authSlice = useSelector((state) => state.auth);
  const isAuthenticated = authSlice.isAuthenticated;

  const dispatch = useDispatch();

  useEffect(() => {
    getAllAuthors()
      .then((authors) => {
        dispatch(setAuthors(authors));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingAuthors(false));
    getAllTags()
      .then((tags) => {
        dispatch(setTags(tags));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingtags(false));
  }, [dispatch]);

  const handleLogout = () => {
    auth
      .signOut()
      .then()
      .catch((e) => {
        console.log(e);
      });
  };

  const Header = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Link to="/">
        <Button size="sm" variant="plain" color="neutral">
          Posts
        </Button>
      </Link>
      <Link to="/tags">
        <Button size="sm" variant="plain" color="neutral">
          Tags
        </Button>
      </Link>
      <Link to="/authors">
        <Button size="sm" variant="plain" color="neutral">
          Authors
        </Button>
      </Link>
      <Button size="sm" variant="plain" color="danger" onClick={handleLogout}>
        Log out
      </Button>
    </Stack>
  );

  const CustomSnackbar = () => {
    return (
      <Snackbar
        open={notify.open}
        color={notify.error ? "danger" : "primary"}
        autoHideDuration={3000}
        variant="outlined"
        size="sm"
        onClose={(_, reason) => {
          if (reason === "clickaway") {
            return;
          }
          dispatch(closeNotify());
        }}
      >
        {notify.message}
      </Snackbar>
    );
  };

  return (
    <Container maxWidth="md">
      <Stack spacing={2} mt={2}>
        {isAuthenticated ? (
          <>
            <Header />
            {loadingTags || loadingAuthors ? (
              <Stack spacing={2} pt={2}>
                <Typography level="body-xs" textAlign="center">
                  Loading..
                </Typography>
              </Stack>
            ) : (
              children
            )}
          </>
        ) : (
          children
        )}
      </Stack>
      <CustomSnackbar />
    </Container>
  );
}
