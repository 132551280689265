import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Typography,
} from "@mui/joy";

export default function Tag({ tag, setTag, saving, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} rowSpacing={2} alignItems="flex-end">
        <Grid xs={12} md={9}>
          <Typography level="title-lg"> Basic Info</Typography>
        </Grid>
        <Grid xs={12} md={3}>
          <FormControl>
            <Button
              fullWidth
              disabled={saving}
              type="submit"
              color="neutral"
              variant="outlined"
            >
              {saving ? "Saving" : "Save"} Basic Info
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Tag Name</FormLabel>
            <Input
              required
              name="name"
              type="text"
              placeholder="Tag Name"
              value={tag.name}
              onChange={(e) =>
                setTag({
                  ...tag,
                  name: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <FormLabel>Tag Color</FormLabel>
            <Input
              required
              name="color"
              type="text"
              placeholder="Tag Color"
              value={tag.color}
              onChange={(e) =>
                setTag({
                  ...tag,
                  color: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <div
            style={{
              width: "100%",
              minHeight: "35px",
              backgroundColor: tag.color || "#000",
              borderRadius: "4px",
            }}
          ></div>
        </Grid>
      </Grid>
    </form>
  );
}
