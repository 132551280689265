import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { authenticate, deAuthenticate } from "../redux/auth";

import { useNavigate } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";

export default function Protected({ children }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authSlice = useSelector((state) => state.auth);
  const isAuthenticated = authSlice.isAuthenticated;

  useEffect(() => {
    const auth = getAuth();
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(authenticate({ user }));
      } else {
        dispatch(deAuthenticate());
        navigate("/auth");
      }
    });
    return () => unregisterAuthObserver();
  }, [navigate, dispatch]);

  return isAuthenticated && children;
}
