import {
  doc,
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

import { db } from "./firebaseConfig";

export const getAllAuthors = async () => {
  const docQuery = query(collection(db, "authors"));
  const docSnapshot = await getDocs(docQuery);
  const docs = [];
  docSnapshot.forEach((doc) =>
    docs.push({
      id: doc.id,
      ...doc.data(),
    })
  );
  return docs;
};

export const getAllTags = async () => {
  const docQuery = query(collection(db, "tags"));
  const docSnapshot = await getDocs(docQuery);
  const docs = [];
  docSnapshot.forEach((doc) =>
    docs.push({
      id: doc.id,
      ...doc.data(),
    })
  );
  return docs;
};

export const updateAuthor = async (id, data) => {
  await updateDoc(doc(db, "authors", id), data);
};

export const updateTag = async (id, data) => {
  await updateDoc(doc(db, "tags", id), data);
};

export const addTag = async (data) => {
  const docRef = await addDoc(collection(db, "tags"), data);
  return docRef.id;
};

export const addAuthor = async (data) => {
  const docRef = await addDoc(collection(db, "authors"), data);
  return docRef.id;
};

export const addArticle = async (data) => {
  const docRef = await addDoc(collection(db, "articles"), data);
  return docRef.id;
};

export const getAllArticles = async () => {
  const docQuery = query(
    collection(db, "articles"),
    orderBy("publishedAt", "desc")
  );
  const docSnapshot = await getDocs(docQuery);
  const articles = [];
  docSnapshot.forEach((doc) =>
    articles.push({
      id: doc.id,
      title: doc.get("title"),
      slug: doc.get("slug"),
      isPublished: Boolean(doc.get("isPublished")),
    })
  );
  return articles;
};

export const getArticle = async (id) => {
  const docRef = doc(db, "articles", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() };
  } else {
    return {};
  }
};

export const updateArticle = async (id, data) => {
  await updateDoc(doc(db, "articles", id), data);
};
