import { Link } from "react-router-dom";

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Textarea,
  Typography,
} from "@mui/joy";
import Markdown from "react-markdown";

export default function Author({ author, setAuthor, saving, handleSubmit }) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} rowSpacing={2} alignItems="flex-end">
        <Grid xs={12} md={9}>
          <Typography level="title-lg"> Basic Info</Typography>
        </Grid>
        <Grid xs={12} md={3}>
          <FormControl>
            <Button
              fullWidth
              disabled={saving}
              type="submit"
              color="neutral"
              variant="outlined"
            >
              {saving ? "Saving" : "Save"} Basic Info
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Full Name</FormLabel>
            <Input
              required
              name="name"
              type="text"
              placeholder="Full Name"
              value={author.name}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  name: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <FormLabel>Exterial Profile URL</FormLabel>
            <Input
              required
              name="Exterial Profile URL"
              type="url"
              placeholder="URL"
              value={author.external_profile_url}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  external_profile_url: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <Link to={author.external_profile_url} target="_blank">
            <Typography level="body-xs">Open URL ↗</Typography>
          </Link>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <FormLabel>Avatar Image</FormLabel>
            <Input
              required
              name="Avatar Image"
              type="text"
              placeholder="Choose an Image"
              value={author.image}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  image: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <Link to={author.image} target="_blank">
            <Typography level="body-xs">View Avatar Image ↗</Typography>
          </Link>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <FormLabel>HD Image</FormLabel>
            <Input
              required
              name="HD Image"
              type="text"
              placeholder="Choose an Image"
              value={author.imageHd}
              onChange={(e) =>
                setAuthor({
                  ...author,
                  imageHd: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <Link to={author.imageHd} target="_blank">
            <Typography level="body-xs">View HD Image ↗</Typography>
          </Link>
        </Grid>
        <Grid xs={12}>
          <Tabs
            aria-label="Basic tabs"
            defaultValue={0}
            sx={{
              backgroundColor: "#fff",
            }}
          >
            <TabList>
              <Tab>Editor</Tab>
              <Tab>Preview</Tab>
            </TabList>
            <TabPanel
              value={0}
              sx={{
                padding: 0,
              }}
            >
              <FormControl>
                <FormHelperText>
                  <Typography
                    level="body-xs"
                    width="100%"
                    textAlign="right"
                    color={author.bio.length > 250 ? "danger" : "neutral"}
                  >
                    {author.bio.length}/250
                  </Typography>
                </FormHelperText>
                <Textarea
                  required
                  autoFocus={false}
                  minRows={5}
                  maxRows={10}
                  placeholder="Type the bio here..."
                  value={author.bio}
                  onChange={(e) =>
                    setAuthor({
                      ...author,
                      bio: e.target.value,
                    })
                  }
                />
              </FormControl>
            </TabPanel>
            <TabPanel value={1}>
              <Markdown className="markdown">{author.bio}</Markdown>
            </TabPanel>
          </Tabs>
        </Grid>
      </Grid>
    </form>
  );
}
