import { BrowserRouter, Route, Routes } from "react-router-dom";

// auth
import Auth from "./pages/auth";

// post
import Posts from "./pages/posts";
import Post from "./pages/post/update";

// tag
import Tags from "./pages/tags";
import NewTag from "./pages/tag/new";
import UpdateTag from "./pages/tag/update";

// author
import Authors from "./pages/authors";
import NewAuthor from "./pages/author/new";
import UpdateAuthor from "./pages/author/update";

// hoc
import AppWrapper from "./hoc/appWrapper";
import Protected from "./hoc/protected";

function App() {
  return (
    <BrowserRouter>
      <AppWrapper>
        <Routes>
          <Route
            path="/"
            element={
              <Protected>
                <Posts />
              </Protected>
            }
          />
          <Route path="/auth" element={<Auth />} />
          <Route
            path="/posts/:slug"
            element={
              <Protected>
                <Post />
              </Protected>
            }
          />
          <Route
            path="/tags"
            element={
              <Protected>
                <Tags />
              </Protected>
            }
          />
          <Route
            path="/tags/new"
            element={
              <Protected>
                <NewTag />
              </Protected>
            }
          />
          <Route
            path="/tags/:id"
            element={
              <Protected>
                <UpdateTag />
              </Protected>
            }
          />
          <Route
            path="/authors"
            element={
              <Protected>
                <Authors />
              </Protected>
            }
          />
          <Route
            path="/authors/new"
            element={
              <Protected>
                <NewAuthor />
              </Protected>
            }
          />
          <Route
            path="/authors/:id"
            element={
              <Protected>
                <UpdateAuthor />
              </Protected>
            }
          />
        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
}

export default App;
