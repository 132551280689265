import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import authors from "./authors";
import tags from "./tags";
import utils from "./utils";

export default configureStore({
  reducer: {
    auth,
    authors,
    tags,
    utils,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["auth/authenticate"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["auth.user"],
        // Ignore these paths in the state
        ignoredPaths: ["auth.user"],
      },
    }),
});
