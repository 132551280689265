import { useParams } from "react-router-dom";
import { getArticle } from "../../api";
import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/joy";
import Article from "./article";

export default function Post() {
  const [article, setArticle] = useState(null);
  const [loadingArticle, setLoadingArticle] = useState(true);

  const { slug } = useParams();

  useEffect(() => {
    getArticle(slug)
      .then((article) => {
        setLoadingArticle(false);
        setArticle(article);
      })
      .catch((err) => console.log(err));
  }, [slug]);

  return (
    <Stack spacing={2} pt={2}>
      {loadingArticle ? (
        <Typography level="body-xs" textAlign="center">
          Loading..
        </Typography>
      ) : (
        <Article article={article} setArticle={setArticle} />
      )}
    </Stack>
  );
}
