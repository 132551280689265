import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTags } from "../../redux/tags";
import { addTag } from "../../api";
import Tags from "./tag";
import { useNavigate } from "react-router-dom";

export default function NewTag() {
  const defaultTag = {
    name: "",
    color: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tagSlice = useSelector((state) => state.tags);
  const tags = tagSlice.tags;

  const [saving, setSaving] = useState(false);
  const [tag, setTag] = useState(defaultTag);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    addTag(tag)
      .then((id) => {
        dispatch(
          setTags([
            ...tags,
            {
              ...tag,
              id,
            },
          ])
        );
        navigate(`/tags/${id}`);
      })
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  return (
    <Tags
      tag={tag}
      setTag={setTag}
      saving={saving}
      handleSubmit={handleSubmit}
    />
  );
}
