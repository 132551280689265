import { Table, Stack, Typography, Button } from "@mui/joy";
import { useEffect, useState } from "react";
import { addArticle, getAllArticles } from "../../api";
import { Link, useNavigate } from "react-router-dom";

export default function Posts() {
  const [articles, setArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);

  const [creatingArticle, setCreatingArticle] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAllArticles()
      .then((articles) => {
        setLoadingArticles(false);
        setArticles(articles);
      })
      .catch((err) => console.log(err));
  }, []);

  const createNewArticle = async () => {
    setCreatingArticle(true);
    const id = await addArticle({
      title: "New Post",
      description: "",
      slug: "",
      read_time: 5,
      keywords: [],
      author: "",
      tags: [],
      image: "",
      imageHd: "",
      content: "",
      isPublished: false,
      publishedAt: Date.now(),
    });
    setCreatingArticle(false);
    navigate(`/posts/${id}`);
  };

  return (
    <Stack spacing={2} pt={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg" textAlign="center">
          Posts
        </Typography>
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          onClick={createNewArticle}
          disabled={creatingArticle}
        >
          {creatingArticle ? "Creating" : "+ New"}
        </Button>
      </Stack>
      <Table aria-label="basic table">
        <thead>
          <tr>
            <th>Sr. no.</th>
            <th style={{ width: "70%" }}>Title</th>
            <th>Published</th>
          </tr>
        </thead>
        <tbody>
          {loadingArticles ? (
            <tr>
              <td colSpan={3}>
                <Typography level="body-xs" textAlign="center">
                  Loading..
                </Typography>
              </td>
            </tr>
          ) : (
            articles.map((article, index) => (
              <tr key={index}>
                <td>{articles.length - index}</td>
                <td>
                  <Link to={`/posts/${article.id}`}>
                    <Typography level="body-sm">{article.title}</Typography>
                  </Link>
                </td>
                <td>{article.isPublished ? "✅" : "❌"}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Stack>
  );
}
