import { useEffect } from "react";

import { signInWithPopup } from "firebase/auth";

import { useSelector, useDispatch } from "react-redux";
import { authenticate } from "../../redux/auth";

import { useNavigate } from "react-router-dom";

import { auth, googleAuthProvider } from "../../firebaseConfig";
import { Button, Container, Stack, Typography } from "@mui/joy";
import { setNotify } from "../../redux/utils";

export default function Auth() {
  const authSlice = useSelector((state) => state.auth);
  const isAuthenticated = authSlice.isAuthenticated;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated, navigate]);

  const handleLogin = async (provider) => {
    try {
      const { user } = await signInWithPopup(auth, provider);

      // update redux store
      const payload = { user };
      dispatch(authenticate(payload));

      navigate("/");
    } catch (err) {
      console.error(`Authentication error: ${err}`);
      dispatch(
        setNotify({
          open: true,
          message: "Unable to login",
          error: true,
        })
      );
    }
  };

  return (
    <Container maxWidth="md">
      <Stack
        spacing={2}
        sx={{
          minHeight: "70vh",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography level="title-lg" textAlign="center">
          Login 🛡️
        </Typography>
        <Typography level="body-xs" textAlign="center">
          Only admins 🔐 would be able to continue...
        </Typography>
        <Button variant="soft" onClick={() => handleLogin(googleAuthProvider)}>
          Continue with Google
        </Button>
      </Stack>
    </Container>
  );
}
