import { Grid, Stack, Switch, Typography } from "@mui/joy";
import { updateArticle } from "../../api";
import { Link } from "react-router-dom";
import { BLOG_URL } from "../../utils/config";
import { useState } from "react";

export default function ArticleStatusSwitch({ article, setArticle }) {
  const [saving, setSaving] = useState(false);

  const isPublishable = () => {
    if (
      article.title &&
      article.slug &&
      article.read_time &&
      article.author &&
      article.content &&
      article.description &&
      article.keywords.length &&
      article.tags.length &&
      article.imageHd &&
      article.image
    )
      return true;
  };

  const handleSwitch = () => {
    setSaving(true);
    const data = {
      isPublished: !article.isPublished,
    };
    console.log(!isPublishable());
    if (!article.isPublished && !isPublishable()) {
      alert("All fields are required");
      setSaving(false);
      return;
    }

    updateArticle(article.id, data)
      .then(() => {
        const newArticleData = { ...article, ...data };
        setArticle(newArticleData);
      })
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  const getPreviewURL = () => {
    let searchParams = "";
    if (!article.isPublished) {
      searchParams = "?preview=1";
    }
    return `${BLOG_URL}/${article.slug}${searchParams}`;
  };

  return (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid xs={12} md={9}>
        <Typography level="title-lg">Publish</Typography>
      </Grid>
      <Grid xs={12} md={3}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Link to={getPreviewURL()} target="_blank">
            <Typography level="body-xs" textAlign="center">
              {article.isPublished ? "View" : "Preview"} ↗
            </Typography>
          </Link>
          <Switch
            checked={article.isPublished}
            onChange={handleSwitch}
            disabled={saving}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
