import { Table, Stack, Typography, Avatar, Button } from "@mui/joy";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Authors() {
  const authorSlice = useSelector((state) => state.authors);
  const authors = authorSlice.authors;

  return (
    <Stack spacing={2} pt={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg" textAlign="center">
          Authors
        </Typography>
        <Link to="/authors/new">
          <Button variant="outlined" color="neutral" size="sm">
            + New
          </Button>
        </Link>
      </Stack>
      <Table aria-label="basic table">
        <thead>
          <tr>
            <th>Sr. no.</th>
            <th style={{ width: "70%" }}>Name</th>
            <th>Avatar</th>
          </tr>
        </thead>
        <tbody>
          {authors.map((author, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/authors/${author.id}`}>
                  <Typography level="body-sm">{author.name}</Typography>
                </Link>
              </td>
              <td>
                <Avatar size="sm" src={author.image} alt={author.name} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  );
}
