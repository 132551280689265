import { createSlice } from "@reduxjs/toolkit";

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    activePathId: null,
    notify: {
      open: false,
      message: null,
      error: false,
    },
  },
  reducers: {
    setActivePathId: (state, { payload }) => {
      state.activePathId = payload;
    },
    setNotify: (state, { payload }) => {
      state.notify = {
        open: true,
        message: payload.message,
        error: Boolean(payload.error),
      };
    },
    closeNotify: (state) => {
      state.notify = {
        open: false,
        action: null,
      };
    },
  },
});

export const { setActivePathId, setNotify, closeNotify } = utilsSlice.actions;

export default utilsSlice.reducer;
