import { createSlice } from "@reduxjs/toolkit";

export const authorsSlice = createSlice({
  name: "authors",
  initialState: {
    authors: [],
  },
  reducers: {
    setAuthors: (state, { payload }) => {
      state.authors = payload;
    },
  },
});

export const { setAuthors } = authorsSlice.actions;

export default authorsSlice.reducer;
