import { Table, Stack, Typography, Button } from "@mui/joy";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Tags() {
  const tagSlice = useSelector((state) => state.tags);
  const tags = tagSlice.tags;

  return (
    <Stack spacing={2} pt={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg" textAlign="center">
          Tags
        </Typography>
        <Link to="/tags/new">
          <Button variant="outlined" color="neutral" size="sm">
            + New
          </Button>
        </Link>
      </Stack>
      <Table aria-label="basic table">
        <thead>
          <tr>
            <th>Sr. no.</th>
            <th style={{ width: "70%" }}>Title</th>
            <th>Published</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/tags/${tag.id}`}>
                  <Typography level="body-sm">{tag.name}</Typography>
                </Link>
              </td>
              <td>
                <Typography
                  level="body-sm"
                  bgcolor={tag.color || "neutral"}
                  textColor={"#fff"}
                  textAlign="center"
                >
                  {tag.color || "N/A"}
                </Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  );
}
