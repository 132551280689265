import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthors } from "../../redux/authors";
import { updateAuthor } from "../../api";

import Author from "./author";

export default function UpdateAuthor() {
  const { id } = useParams();

  const dispatch = useDispatch();

  const authorSlice = useSelector((state) => state.authors);
  const authors = authorSlice.authors;

  const [author, setAuthor] = useState(authors.filter((i) => i.id === id)[0]);
  const [saving, setSaving] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    updateAuthor(author.id, author)
      .then(() =>
        dispatch(
          setAuthors(
            authors.map((i) => {
              if (i.id === author.id) {
                return author;
              }
              return i;
            })
          )
        )
      )
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  return (
    <Author
      author={author}
      setAuthor={setAuthor}
      saving={saving}
      handleSubmit={handleSubmit}
    />
  );
}
