import { useState } from "react";

import {
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
  Textarea,
  Typography,
  Select,
  Option,
  Avatar,
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from "@mui/joy";

import { kebabCase } from "lodash";

import { updateArticle } from "../../api";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";

export default function ArticleTextComponents({ article, setArticle }) {
  const [saving, setSaving] = useState(false);
  const [keyword, setKeyword] = useState("");

  const tagSlice = useSelector((state) => state.tags);
  const tags = tagSlice.tags;

  const authorSlice = useSelector((state) => state.authors);
  const authors = authorSlice.authors;

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    const data = {
      title: article.title,
      description: article.description,
      slug: article.slug,
      read_time: parseInt(article.read_time),
      keywords: [...new Set(article.keywords)] || [],
      author: article.author,
      tags: article.tags,
      image: article.image,
      imageHd: article.imageHd,
      content: article.content,
    };
    updateArticle(article.id, data)
      .then(() => setArticle({ ...article, ...data }))
      .catch((err) => console.error(err))
      .finally(() => setSaving(false));
  };

  const generateSlug = () => {
    setArticle({
      ...article,
      slug: kebabCase(article.title),
    });
  };

  const removePrefixSpace = (string) => {
    if (string.length > 1) {
      if (string.slice(0, 1) === " ") {
        return string.slice(1, string.length);
      }
      return string;
    }
    return string;
  };

  const addTag = () => {
    if (keyword.length) {
      setArticle({
        ...article,
        keywords: [
          ...new Set([
            ...(article.keywords || []),
            ...keyword.split(",").filter((t) => removePrefixSpace(t)),
          ]),
        ],
      });
      setKeyword("");
    }
  };

  const deleteKeyword = (keyword) => {
    setArticle({
      ...article,
      keywords: article.keywords.filter((i) => i !== keyword),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container columnSpacing={2} rowSpacing={2} alignItems="flex-end">
        <Grid xs={12} md={9}>
          <Typography level="title-lg"> Basic Info</Typography>
        </Grid>
        <Grid xs={12} md={3}>
          <FormControl>
            <Button
              fullWidth
              disabled={saving}
              type="submit"
              color="neutral"
              variant="outlined"
            >
              {saving ? "Saving" : "Save"}
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              required
              name="title"
              type="text"
              placeholder="Title"
              value={article.title}
              onChange={(e) =>
                setArticle({
                  ...article,
                  title: e.target.value,
                })
              }
            />
            <FormHelperText>
              <Typography
                level="body-xs"
                width="100%"
                textAlign="right"
                color={article.title.length > 80 ? "danger" : "neutral"}
              >
                {article.title.length}/80
              </Typography>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Textarea
              required
              minRows={2}
              maxRows={3}
              name="Description"
              type="text"
              placeholder="Description"
              value={article.description}
              onChange={(e) =>
                setArticle({
                  ...article,
                  description: e.target.value,
                })
              }
            />
            <FormHelperText>
              <Typography
                level="body-xs"
                width="100%"
                textAlign="right"
                color={article.description?.length > 165 ? "danger" : "neutral"}
              >
                {article.description?.length || 0}/165
              </Typography>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <Stack direction="row" flexWrap="wrap" pb={1}>
              {article.keywords &&
                article.keywords.map((keyword, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "2px",
                    }}
                  >
                    <Chip
                      size="sm"
                      variant="soft"
                      color="danger"
                      endDecorator={
                        <ChipDelete onDelete={() => deleteKeyword(keyword)} />
                      }
                      onClick={() => {
                        deleteKeyword(keyword);
                        setKeyword(keyword);
                      }}
                    >
                      {keyword}
                    </Chip>
                  </div>
                ))}
            </Stack>
            <Textarea
              minRows={1}
              maxRows={5}
              placeholder="Add tags & hit Enter"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  addTag();
                }
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <FormControl>
            <Button
              fullWidth
              disabled={!keyword.length}
              onClick={addTag}
              variant="outlined"
              color="neutral"
            >
              Add Tag
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={12} md={9}>
          <FormControl>
            <FormLabel>Slug</FormLabel>
            <Input
              required
              name="slug"
              type="text"
              placeholder="Slug"
              value={article.slug}
              onChange={(e) =>
                setArticle({
                  ...article,
                  slug: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={3}>
          <FormControl>
            <Button
              fullWidth
              onClick={generateSlug}
              variant="outlined"
              color="neutral"
            >
              Get slug
            </Button>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Read Time</FormLabel>
            <Input
              required
              name="Read Time"
              type="number"
              placeholder="8(min)"
              value={article.read_time}
              onChange={(e) =>
                setArticle({
                  ...article,
                  read_time: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Author</FormLabel>
            <Select
              required
              placeholder="Select an Author"
              name="Author"
              value={article.author || null}
              onChange={(_, newValue) => {
                setArticle({
                  ...article,
                  author: newValue,
                });
              }}
            >
              {authors?.map &&
                authors.map((author, index) => (
                  <Option value={author.id} key={index}>
                    <Avatar size="sm" src={author.image} alt={author.name} />{" "}
                    {author.name}
                  </Option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <FormLabel>Tags</FormLabel>
            <Select
              multiple
              required
              placeholder="Select Tags"
              name="Tags"
              value={article.tags || null}
              onChange={(_, newValue) => {
                setArticle({
                  ...article,
                  tags: newValue,
                });
              }}
            >
              {tags?.map &&
                tags.map((tag, index) => (
                  <Option value={tag.id} key={index}>
                    {tag.name}
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: tag.color || "#ffffff",
                      }}
                    ></div>
                  </Option>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <FormLabel>Thumbnail Image</FormLabel>
              <Input
                fullWidth
                required
                name="Thumbnail Image"
                type="text"
                placeholder="Choose an Image"
                value={article.image}
                onChange={(e) =>
                  setArticle({
                    ...article,
                    image: e.target.value,
                  })
                }
              />
            </FormControl>
            <Link to={article.image} target="_blank">
              <Stack direction="row" spacing={2}>
                <Avatar alt={article.title} src={article.image} />
                <Typography level="body-xs">↗</Typography>
              </Stack>
            </Link>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={2}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <FormLabel>HD Image</FormLabel>
              <Input
                fullWidth
                required
                name="HD Image"
                type="text"
                placeholder="Choose an Image"
                value={article.imageHd}
                onChange={(e) =>
                  setArticle({
                    ...article,
                    imageHd: e.target.value,
                  })
                }
              />
            </FormControl>
            <Link to={article.imageHd} target="_blank">
              <Stack direction="row" spacing={2}>
                <Avatar alt={article.title} src={article.imageHd} />
                <Typography level="body-xs">↗</Typography>
              </Stack>
            </Link>
          </Stack>
        </Grid>
        <Grid xs={12}>
          <FormLabel>Content</FormLabel>
        </Grid>
        <Grid xs={12}>
          <Tabs
            aria-label="Basic tabs"
            defaultValue={0}
            sx={{
              backgroundColor: "#fff",
            }}
          >
            <TabList>
              <Tab>Editor</Tab>
              <Tab>Preview</Tab>
            </TabList>
            <TabPanel
              value={0}
              sx={{
                padding: 0,
              }}
            >
              <FormControl
                sx={{
                  mt: 1.75,
                }}
              >
                <FormHelperText>
                  <Typography
                    level="body-xs"
                    width="100%"
                    textAlign="right"
                    color={article.content.length > 2450 ? "danger" : "neutral"}
                  >
                    {article.content.length.toLocaleString()}/2,450
                  </Typography>
                </FormHelperText>
                <Textarea
                  required
                  autoFocus={false}
                  minRows={24}
                  maxRows={50}
                  placeholder="Type the article here..."
                  value={article.content}
                  onChange={(e) =>
                    setArticle({
                      ...article,
                      content: e.target.value,
                    })
                  }
                />
              </FormControl>
            </TabPanel>
            <TabPanel value={1}>
              <Markdown className="markdown">{article.content}</Markdown>
            </TabPanel>
          </Tabs>
        </Grid>
      </Grid>
    </form>
  );
}
